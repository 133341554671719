exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-nolwa-js": () => import("./../../../src/pages/AboutNolwa.js" /* webpackChunkName: "component---src-pages-about-nolwa-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/Contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-design-miami-js": () => import("./../../../src/pages/DesignMiami.js" /* webpackChunkName: "component---src-pages-design-miami-js" */),
  "component---src-pages-designers-designer-details-js": () => import("./../../../src/pages/Designers/DesignerDetails.js" /* webpackChunkName: "component---src-pages-designers-designer-details-js" */),
  "component---src-pages-designers-index-js": () => import("./../../../src/pages/Designers/index.js" /* webpackChunkName: "component---src-pages-designers-index-js" */),
  "component---src-pages-enquiry-js": () => import("./../../../src/pages/Enquiry.js" /* webpackChunkName: "component---src-pages-enquiry-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-product-product-desc-js": () => import("./../../../src/pages/Product/ProductDesc.js" /* webpackChunkName: "component---src-pages-product-product-desc-js" */),
  "component---src-pages-product-product-full-image-js": () => import("./../../../src/pages/Product/ProductFullImage.js" /* webpackChunkName: "component---src-pages-product-product-full-image-js" */),
  "component---src-pages-product-product-quote-js": () => import("./../../../src/pages/Product/ProductQuote.js" /* webpackChunkName: "component---src-pages-product-product-quote-js" */),
  "component---src-pages-product-productmain-js": () => import("./../../../src/pages/Product/Productmain.js" /* webpackChunkName: "component---src-pages-product-productmain-js" */),
  "component---src-pages-the-bidri-collection-js": () => import("./../../../src/pages/TheBidriCollection.js" /* webpackChunkName: "component---src-pages-the-bidri-collection-js" */),
  "component---src-pages-the-bidri-craft-js": () => import("./../../../src/pages/TheBidriCraft.js" /* webpackChunkName: "component---src-pages-the-bidri-craft-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-videos-js": () => import("./../../../src/templates/videos.js" /* webpackChunkName: "component---src-templates-videos-js" */)
}

